import styled from "@emotion/styled"
import theme from "../../particles/Theme"

const StyledLabelImage = styled.figure`
  text-align: center;

  .gatsby-image-wrapper {
    height: 150px;
    width: 200px;
  }
`

export default StyledLabelImage
