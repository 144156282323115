import React, { FC } from "react"
import StyledLabelImage from "./LabelImage.style"
import { GatsbyImage } from "gatsby-plugin-image";
import { isInternal } from "../../particles/helpers"
import { Link } from "gatsby"

const LabelImage = ({ label, image, url, lightbox, className }: Props) => {
  const imageComponent = image => (
    <GatsbyImage
      image={image.localFile.childImageSharp.gatsbyImageData}
      objectFit="contain"
      alt={image.altText}
      style={{ height: "150px", width: "200px" }} />
  )

  return (
    <StyledLabelImage className={className}>
      {url && isInternal(url) ? (
        <Link to={url}>{imageComponent(image)}</Link>
      ) : (
        <a href={url} target="_blank" rel="noopener">
          {imageComponent(image)}
        </a>
      )}
    </StyledLabelImage>
  )
}

type Props = {
  label?: string
  image: MediaProps
  className?: string
  url?: string
  lightbox?
}

type MediaProps = {
  altText: string
  localFile: {
    childImageSharp: {
      fluid: []
    }
  }
}

export default LabelImage
