import React from "react"
import StyledSubHeading from "./SubHeading.style"

const SubHeading = ({ title, center, dark, style }: SubHeadingProps) => {
  return (
    <StyledSubHeading
      center={center || undefined}
      dark={dark || undefined}
      style={style}
      dangerouslySetInnerHTML={{ __html: title }}
    />
  )
}

type SubHeadingProps = {
  title: string
  center?: boolean
  dark?: boolean
  style?: {}
}

export default SubHeading
