import React, { useContext } from "react"
import Base from "./Base"
import Banner from "../organisms/banner/Banner"
import Breadcrumbs from "../organisms/breadcrumbs/Breadcrumbs"
import Content from "../particles/Content"
import styled from "@emotion/styled"
import { GlobalStateContext } from "../../context/GlobalContextProvider"
import LabelImage from "../molecules/LabelImage/LabelImage"
import { randomID } from "../particles/helpers"
import SubHeading from "../atoms/SubHeading/SubHeading"
import theme from "../particles/Theme"
import { useVendorData } from "../particles/hooks/useVendorData"

const StyledFinalPage = styled.div`
  margin: 2rem 0;

  @media (max-width: ${theme.breakpoints[1]}) {
    text-align: center;
  }

  ul.columns {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;

    @media (max-width: ${theme.breakpoints[1]}) {
      display: inline-block;
      columns: 1;
      -webkit-columns: 1;
      -moz-columns: 1;
    }

    @media (max-width: ${theme.breakpoints[0]}) {
      columns: 1;
      -webkit-columns: 1;
      -moz-columns: 1;

      display: initial;

      li {
        list-style: none;
      }
    }

    li {
      -webkit-column-break-inside: avoid;
      page-break-inside: avoid;
      break-inside: avoid;
    }
  }

  ul {
    @media (max-width: ${theme.breakpoints[0]}) {
      padding-left: 0;
    }
  }

  li {
    @media (max-width: ${theme.breakpoints[0]}) {
      list-style: none;
    }
  }
`

const Vendors = styled.section`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 2rem 0;

  @media (max-width: ${theme.breakpoints[1]}) {
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;

    figure.full-width {
      grid-column: 1/3;
    }
  }

  @media (max-width: ${theme.breakpoints[0]}) {
    display: block;

    figure {
      display: flex;
      justify-content: center;
    }
  }

  h2,
  h5 {
    grid-column: 1/5;
  }
`

const getBranch = (branchData, state) => {
  let selectedBranch = null

  if (branchData) {
    branchData.forEach(branch => {
      if (state && state.branch === branch.title) selectedBranch = branch
    })
  }

  return selectedBranch
}

const getProductOfferings = (selectedBranch, currentProduct) => {
  let productOfferings = null

  if (selectedBranch) {
    let products = Object.values(
      selectedBranch.productSelection.residentialProducts
    )

    products.map(product => {
      if (product.catname === currentProduct) {
        productOfferings = product
      }
      return product
    })
  }

  return productOfferings
}


const ProductFinal = ({ pageContext }) => {
  const state = useContext(GlobalStateContext)
  const allBranches = pageContext.branches
  const selectedBranch = getBranch(allBranches, state)
  const productOfferings = getProductOfferings(selectedBranch, pageContext.title)
  const vendors = useVendorData()
  let branchProducts

  if (productOfferings) { branchProducts = Object.entries(productOfferings)}

  return (
    <Base title={pageContext.title}>
      <Banner title={pageContext.title} />
      <Breadcrumbs />
      <StyledFinalPage>
        <Content style={{ paddingBottom: `5rem` }}>
          {selectedBranch ? (
            <>
              <SubHeading
                title={`${selectedBranch.title} <br /> ${pageContext.title}`}
                center={true}
              />
              {Array.isArray(branchProducts) &&
                branchProducts.map(product => {
                  if (product[0] === "products" && product[1]) {
                    return (
                      <section key={randomID()}>
                        <SubHeading
                          title="Products"
                          style={{ margin: `5rem 0` }}
                        />
                        <ul className="columns">
                          {Array.isArray(product[1]) &&
                            product[1].map(p => <li key={randomID()}>{p}</li>)}
                        </ul>
                      </section>
                    )
                  } else if (product[0] === "vendors" && product[1]) {
                    return (
                      <Vendors key={randomID()}>
                        <SubHeading
                          title="Vendors"
                          style={{ margin: `5rem 0` }}
                        />
                        {Array.isArray(product[1]) &&
                          product[1].map(vendor => {
                            let vendorData
                            vendors.forEach(v => {
                              if (v.node.title === vendor.replace(/_/g, " ")) {
                                vendorData = v.node
                              }
                            })

                            return (
                              <LabelImage
                                image={vendorData.VendorInfo.logo}
                                url={vendorData.VendorInfo.externalUrl}
                                key={randomID()}
                              />
                            )
                          })}
                      </Vendors>
                    )
                  } else if (
                    (product[0] === "subcat1" ||
                      product[0] === "subcat2" ||
                      product[0] === "subcat3" ||
                      product[0] === "subcat4" ||
                      product[0] === "subcat5") &&
                    product[1].subcatname &&
                    (product[1].products ||
                      product[1].vendors ||
                      (product[1].subcat1.subcatname &&
                        (product[1].subcat1.products ||
                          product[1].subcat1.vendors)) ||
                      (product[1].subcat2.subcatname &&
                        (product[1].subcat2.products ||
                          product[1].subcat2.vendors)) ||
                      (product[1].subcat3.subcatname &&
                        (product[1].subcat3.products ||
                          product[1].subcat3.vendors)))
                  ) {
                    const productLevels = Object.entries(product[1])

                    return (
                      <section key={randomID()}>
                        <SubHeading
                          title={product[1].subcatname}
                          style={{ margin: `5rem 0` }}
                        />
                        {product[1].products &&
                        Array.isArray(product[1].products) ? (
                          <>
                            <h5>Products</h5>
                            <ul className="columns">
                              {product[1].products.map(product => {
                                return <li key={randomID()}>{product}</li>
                              })}
                            </ul>
                          </>
                        ) : null}

                        {product[1].vendors &&
                        Array.isArray(product[1].vendors) ? (
                          <Vendors key={randomID()}>
                            <h5>Vendors</h5>
                            {product[1].vendors.map((vendor, idx) => {
                              let vendorData
                              vendors.forEach(v => {
                                if (
                                  v.node.title === vendor.replace(/_/g, " ")
                                ) {
                                  vendorData = v.node
                                }
                              })
                              return (
                                <LabelImage
                                  image={vendorData.VendorInfo.logo}
                                  url={vendorData.VendorInfo.externalUrl}
                                  key={randomID()}
                                />
                              )
                            })}
                          </Vendors>
                        ) : null}
                        {productLevels.map(level => {
                          return (level[0] === "subcat1" ||
                            level[0] === "subcat2" ||
                            level[0] === "subcat3") &&
                            (level[1].products || level[1].vendors) ? (
                            <section key={randomID()}>
                              <h5>{level[1].subcatname}</h5>
                              {level[1].products ? (
                                <ul>
                                  {level[1].products.map(product => (
                                    <li key={randomID()}>{product}</li>
                                  ))}
                                </ul>
                              ) : null}
                              {level[1].vendors ? (
                                <Vendors key={randomID()}>
                                  {level[1].vendors.map((vendor, idx) => {
                                    let vendorData
                                    vendors.forEach(v => {
                                      if (
                                        v.node.title ===
                                        vendor.replace(/_/g, " ")
                                      ) {
                                        vendorData = v.node
                                      }
                                    })

                                    return (
                                      <LabelImage
                                        image={vendorData.VendorInfo.logo}
                                        url={vendorData.VendorInfo.externalUrl}
                                        key={randomID()}
                                        className={
                                          level[1].vendors.length - idx === 1 &&
                                          idx % 2 !== 1
                                            ? `full-width`
                                            : null
                                        }
                                      />
                                    )
                                  })}
                                </Vendors>
                              ) : null}
                            </section>
                          ) : null
                        })}
                        {/* {subCat.vendors &&
                                            subCat.vendors.length > 0 ? (
                                              <Vendors key={randomID()}>
                                                {subCat.vendors.map(
                                                  (vendor, idx) => {
                                                    if (
                                                      typeof vendor ===
                                                        "object" &&
                                                      vendor !== null &&
                                                      vendor.VendorInfo.logo
                                                        .localFile
                                                        .childImageSharp
                                                    ) {
                                                      return (
                                                        <LabelImage
                                                          image={
                                                            vendor.VendorInfo
                                                              .logo
                                                          }
                                                          url={
                                                            vendor.VendorInfo
                                                              .externalUrl
                                                          }
                                                          key={randomID()}
                                                          className={
                                                            subCat.vendors
                                                              .length -
                                                              idx ===
                                                              1 && idx % 2 !== 1
                                                              ? `full-width`
                                                              : null
                                                          }
                                                        />
                                                      )
                                                    }
                                                  }
                                                )}
                                              </Vendors>
                                            ) : null} */}
                        {/* </section>
                                        )
                                      }
                                    )
                                  : null} */}
                      </section>
                    )
                  } else {
                    return null
                  }
                })}
            </>
          ) : (
            pageContext.subLevels &&
            pageContext.subLevels.map(subLevel => {
              if (subLevel.subLevelType === "Products/Types") {
                return (
                  <section key={randomID()}>
                    <SubHeading title="Products" style={{ margin: `5rem 0` }} />
                    <ul className="columns">
                      {subLevel.productType.map(product => (
                        <li key={randomID()}>{product.name}</li>
                      ))}
                    </ul>
                  </section>
                )
              } else if (subLevel.subLevelType === "Vendors") {
                return (
                  <Vendors key={randomID()}>
                    <SubHeading title="Vendors" style={{ margin: `5rem 0` }} />
                    {subLevel &&
                      subLevel.vendors.map(vendor => {
                        return (
                          <LabelImage
                            image={vendor.VendorInfo.logo}
                            url={vendor.VendorInfo.externalUrl}
                            key={randomID()}
                          />
                        )
                      })}
                  </Vendors>
                )
              } else if (subLevel.subLevelType === "Category") {
                return (
                  <section key={randomID()}>
                    <SubHeading
                      title={subLevel.categoryName}
                      style={{ margin: `5rem 0` }}
                    />
                    {subLevel.categorySubLevel.products &&
                    subLevel.categorySubLevel.products.length > 0 &&
                    subLevel.categorySubLevel.products[0].name !== null ? (
                      <>
                        <h5>Products</h5>
                        <ul>
                          {subLevel.categorySubLevel.products.map(product => {
                            return <li key={randomID()}>{product.name}</li>
                          })}
                        </ul>
                      </>
                    ) : null}
                    {subLevel.categorySubLevel.vendors &&
                    subLevel.categorySubLevel.vendors.length > 0 ? (
                      <Vendors key={randomID()}>
                        <h5>Vendors</h5>
                        {subLevel.categorySubLevel &&
                          subLevel.categorySubLevel.vendors.map(
                            (vendor, idx) => {
                              if (
                                typeof vendor === "object" &&
                                vendor !== null &&
                                vendor.VendorInfo.logo.localFile.childImageSharp
                              ) {
                                return (
                                  <LabelImage
                                    image={vendor.VendorInfo.logo}
                                    url={vendor.VendorInfo.externalUrl}
                                    key={randomID()}
                                    className={
                                      subLevel.categorySubLevel.vendors.length -
                                        idx ===
                                        1 && idx % 2 !== 1
                                        ? `full-width`
                                        : null
                                    }
                                  />
                                )
                              }
                              return null
                            }
                          )}
                      </Vendors>
                    ) : null}
                    {subLevel.categorySubLevel.subSubcategory
                      ? subLevel.categorySubLevel.subSubcategory.map(subCat => {
                          return (
                            <section key={randomID()}>
                              <h5>{subCat.categoryName}</h5>
                              {subCat.products && subCat.products.length > 0 ? (
                                <ul>
                                  <li>
                                    Products
                                    <ul>
                                      {subCat.products.map(product => (
                                        <li key={randomID()}>
                                          {product.productName}
                                        </li>
                                      ))}
                                    </ul>
                                  </li>
                                </ul>
                              ) : null}
                              {subCat.vendors && subCat.vendors.length > 0 ? (
                                <Vendors key={randomID()}>
                                  {subCat.vendors.map((vendor, idx) => {
                                    if (
                                      typeof vendor === "object" &&
                                      vendor !== null &&
                                      vendor.VendorInfo.logo.localFile
                                        .childImageSharp
                                    ) {
                                      return (
                                        <LabelImage
                                          image={vendor.VendorInfo.logo}
                                          url={vendor.VendorInfo.externalUrl}
                                          key={randomID()}
                                          className={
                                            subCat.vendors.length - idx === 1 &&
                                            idx % 2 !== 1
                                              ? `full-width`
                                              : null
                                          }
                                        />
                                      )
                                    }
                                    return null
                                  })}
                                </Vendors>
                              ) : null}
                            </section>
                          )
                        })
                      : null}
                  </section>
                )
              } else {
                return null
              }
            })
          )}
        </Content>
      </StyledFinalPage>
    </Base>
  )
}
export default ProductFinal
