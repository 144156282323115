import { useStaticQuery, graphql } from "gatsby"

export const useVendorData = () => {
  const wordpress = useStaticQuery(graphql`query getVendors {
  allWpVendor(limit: 500) {
      edges {
        node {
          id
          title
          VendorInfo {
            externalUrl
            logo {
              altText
              uri
              sourceUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  }
`)

 return wordpress.allWpVendor.edges
}
