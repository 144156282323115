import styled from "@emotion/styled"
import theme from "../../particles/Theme"

const StyledSubHeading = styled.h2`
  position: relative;
  line-height: 1.25em;
  color: ${(props: StyledComponentProps) => (props.dark ? `white` : undefined)};
  text-align: ${(props: StyledComponentProps) =>
      props.center ? `center` : `initial`};

  @media (max-width: ${theme.breakpoints[1]}) {
    text-align: center;
  }

  @media (max-width: ${theme.breakpoints[0]}) {
    width: 100% !important;
  }

  &:before {
    content: "";
    position: absolute;
    bottom: -1.5rem;
    left: ${(props: StyledComponentProps) =>
      props.center ? `calc(50% - 30px)` : `0`};
    width: 60px;
    height: 8px;
    background: ${(props: StyledComponentProps) =>
      props.dark ? `white` : `#f15e2d`};

    @media (max-width: ${theme.breakpoints[1]}) {
      left: calc(50% - 30px);
    }
  }
`

type StyledComponentProps = {
  center?: boolean
  dark?: boolean
}

export default StyledSubHeading
